import axios from "@/utils/http";
import base from "@/api/base";

const discipline = {
    // 课程导入模板
    importTemplate() {
        return base.host + '/template/课程导入模板.xlsx'
    },
    import(file) {
        const fd = new FormData()
        fd.append('file', file)
        return axios.post(base.sq + '/school_discipline/import', fd)
    }
}
export default discipline

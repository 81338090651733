import request from "@/utils/request";

export default class XiService {
    /**
     * 获取我的系部
     */
    static async getMy() {
        const resp = await request({
            url: '/v2/school_api/user/my_xi',
        })
        return resp.data
    }

}

<template>
  <div id="subject" v-loading="loading">
    <index>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>管理</el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">课程管理</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div class="nav-tab-bar-box u-f-item u-f-jsb">
        <div class="nav-tab-bar u-f-item">
          <div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == gradeIndex ? 'active' : ''" v-for="(item, index) in gradeMenu" :key="index">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title u-f-item u-f-jsb">
          <div>共{{ total }}条数据</div>
          <div class="u-f-item">
            <el-button size="small" @click="openDialog(1)">添加课程</el-button>
            <v-import style="padding-left: 10px" :download-template="importTemplateUrl" @success="onImport">
              <el-button size="small" type="primary">导入</el-button>
            </v-import>
          </div>
        </div>
        <el-table :data="subjectList" height="66vh" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
          <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
          <el-table-column prop="code" label="课程代码" align="center"></el-table-column>
          <el-table-column prop="name" label="课程名称" align="center"></el-table-column>
          <el-table-column prop="discipline_type.name" label="课程类型" align="center"></el-table-column>
          <el-table-column prop="course_attributes" label="课程属性" align="center"></el-table-column>
          <el-table-column prop="course_nature" label="课程性质" align="center"></el-table-column>
          <el-table-column prop="jy.name" label="教研室名称" align="center"></el-table-column>
          <el-table-column label="考试方式" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.examination_method == 1">考试</span>
              <span v-if="scope.row.examination_method == 2">考查</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="delSubject(scope.row.id)" type="text" size="small" style="color: red;">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="u-f-right" style="padding: 15px 0;">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              :page-size="limit"
              layout="total, prev, pager, next"
              :total="total"
          ></el-pagination>
        </div>
      </div>
      <!-- 添加学科 -->
      <el-dialog :title="showTitle" :visible.sync="addSubject" width="500px" :before-close="handleClose">
        <div class="edit-box">
          <div class="u-f-item input-box">
            <div class="input-lebal">
              <span>*</span>
              课程代码
            </div>
            <el-input v-model="code" placeholder="请输入课程代码" style="width: 75%;"></el-input>
          </div>
          <div class="u-f-item input-box">
            <div class="input-lebal">
              <span>*</span>
              课程名称
            </div>
            <el-input v-model="name" placeholder="请输入名称" style="width: 75%;"></el-input>
          </div>
          <div class="u-f-item input-box">
            <div class="input-lebal">
              <span>*</span>
              课程分类
            </div>
            <el-select v-model="school_discipline_type_id" filterable placeholder="请选择" style="width: 75%;">
              <el-option v-for="item in subjectTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="u-f-item input-box">
            <div class="input-lebal">
              课程性质
            </div>
            <el-input v-model="course_nature" placeholder="请输入课程性质" style="width: 75%;"></el-input>
          </div>
          <div class="u-f-item input-box">
            <div class="input-lebal">
              <span>*</span>
              考试方式
            </div>
            <el-radio-group v-model="examination_method" size="medium">
              <el-radio-button :label="1">考试</el-radio-button>
              <el-radio-button :label="2">考查</el-radio-button>
            </el-radio-group>
          </div>
          <div class="u-f-item input-box">
            <div class="input-lebal">
              课程系数
            </div>
            <el-input v-model="course_coefficient" placeholder="请输入课程系数" style="width: 75%;"></el-input>
          </div>
          <div class="u-f-item input-box">
            <div class="input-lebal">
              <span>*</span>
              系部
            </div>
            <el-select v-model="xi_id" filterable placeholder="请选择" style="width: 75%;">
              <el-option v-for="item in xiList.filter(_ => myXiIds.includes(_.id))" :key="item.id" :label="item.section_name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="u-f-item input-box">
            <div class="input-lebal">
              课程属性
            </div>
            <el-input v-model="course_attributes" placeholder="请输入课程属性" style="width: 75%;"></el-input>
          </div>
          <div class="u-f input-box">
            <div class="input-lebal" style="padding-top: 10px;">
              简介
            </div>
            <el-input type="textarea" :rows="5" v-model="introduction" placeholder="请输入简介" style="width: 75%;"></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
      </el-dialog>
    </index>
  </div>
</template>

<script>
import index from '@/components/common/index.vue';
import VImport from "@/components/import";
import discipline from "@/api/v2/discipline";
import XiService from "@/api/v2/xi";
import UserService from "@/api/v2/user";
export default {
  components: {
    VImport,
    index
  },
  data() {
    return {
      gradeMenu: [{ name: '课程列表' }],
      gradeIndex: 0,
      subjectList: [],
      addSubject: false,
      loading: false,
      editid: '',
      page: 1,
      limit: 10,
      total: 0,
      subjectTypeList: [],
      code: '',
      name: '',
      school_discipline_type_id:'',
      course_nature:'',
      examination_method:1,
      jy_id:'',
      course_coefficient:'',
      difficulty_level:'',
      course_attributes:'',
      theoretical_class_hours:'',
      practice_class_hours:'',
      status:1,
      introduction:'',
      showTitle:'添加课程',
      showType:1,
      StaffList:[],
      xiList: [],
      num:'',
      total_num:'',
      week_num:'',
      js_id:'',
      xi_id: '',
      jsList:[],
      updateVisible1:false,
      file: '',
      fileUrl:'',
      importTemplateUrl: '',
      myXiIds: [],
      rules: [],
    };
  },
  mounted() {
    this.importTemplateUrl = discipline.importTemplate()
    this.getInfo();
    this.getMyXi();
    this.getsubjectTypeList();
    this.getXiList()
    this.getClassRoom()
  },
  methods: {
    async getMyXi() {
      const [res, res1] = await Promise.all([
        UserService.getRules(),
        XiService.getMy(),
      ])
      this.rules = res
      if (this.rules.map(item => item.name).includes('school_api/all')) {
        this.gradeMenu.push({ name: '课程类型' })
      }
      this.myXiIds = res1.xi_ids
    },
    async onImport(file, callback){
      await discipline.import(file)
      callback()
      this.getInfo()
      this.$message.success('导入成功')
    },
    getFile(e){
      if(this.$refs.filElem.files.length){
        this.file = this.$refs.filElem.files[0]
      }
    },
    delFile(){
      this.file = '';
      this.$refs.filElem.value = '';
    },
    // 教室列表
    getClassRoom() {
      this.$api.setting
          .getClassRoom({})
          .then(res => {
            if (res.data.code == 1) {
              let list = res.data.data.rows;
              this.jsList = list;
            } else {
              this.$message.error(res.data.msg);
            }
          });
    },
    // 系部列表
    getXiList() {
      this.$api.setting
          .getXiList({})
          .then(res => {
            if (res.data.code == 1) {
              let list = res.data.data.rows;
              this.xiList = list;
            } else {
              this.$message.error(res.data.msg);
            }
          });
    },
    closeShow(){
      this.init()
      this.addSubject = false;
    },
    openDialog(type,item){
      this.showType = type;
      if(type==1){
        this.showTitle = '添加课程';
      }else if(type==2){
        this.showTitle = '编辑课程';
        this.editid = item.id;
        this.code = item.code;
        this.name = item.name;
        this.school_discipline_type_id = item.school_discipline_type_id;
        this.course_nature = item.course_nature;
        this.examination_method = item.examination_method;
        this.jy_id = item.jy_id;
        this.xi_id = item.xi_id;
        this.course_coefficient = item.course_coefficient;
        this.difficulty_level = item.difficulty_level;
        this.course_attributes = item.course_attributes;
        this.theoretical_class_hours = item.theoretical_class_hours;
        this.practice_class_hours = item.practice_class_hours;
        this.status = item.status;
        this.introduction = item.introduction;
        this.num = item.num;
        this.total_num = item.total_num;
        this.week_num = item.week_num;
        this.js_id = item.js_id;
      }
      this.addSubject = true;
    },
    init(){
      this.code = '';
      this.name = '';
      this.school_discipline_type_id = '';
      this.course_nature = '';
      this.examination_method = 1;
      this.jy_id = '';
      this.course_coefficient = '';
      this.difficulty_level = '';
      this.course_attributes = '';
      this.theoretical_class_hours = '';
      this.practice_class_hours = '';
      this.status = 1;
      this.introduction = '';
      this.num = '';
      this.total_num = '';
      this.week_num = '';
      this.js_id = '';
      this.xi_id = '';
    },
    // 课程分类列表
    getsubjectTypeList() {
      this.$api.setting.subjectTypeList({}).then(res => {
        if (res.data.code == 1) {
          let list = res.data.data.rows;
          this.subjectTypeList = list;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getInfo();
    },
    twacherTap(index) {
      this.gradeIndex = index;
      if (index == 0) {
        this.$router.push('/manageSubject');
      } else if (index == 1) {
        this.$router.push('/subjectType');
      }
    },
    stopDefault(e) {
      e.stopPropagation();
    },
    // 学科信息
    getInfo() {
      this.$api.setting
          .subjectIndex({
            page: this.page,
            limit: this.limit,
            xi: 1,
          })
          .then(res => {
            if (res.data.code == 1) {
              let list = res.data.data.rows;
              this.total = res.data.data.total;
              this.subjectList = list;
            } else {
              this.$message.error(res.data.msg);
            }
          });
    },
    // 关闭弹窗
    handleClose(done) {
      this.init()
      done();
    },
    // 添加学科
    submit() {
      if(this.showType==1){
        this.addSubjects()
      }else{
        this.edit()
      }
    },
    addSubjects(){
      if (this.code == '' || !this.code) {
        return this.$message.error('请输入课程代码');
      }
      if (this.name == '' || !this.name) {
        return this.$message.error('请输入课程名称');
      }
      this.$api.setting.addSubject({
        code: this.code,
        name: this.name,
        school_discipline_type_id:this.school_discipline_type_id,
        course_nature:this.course_nature,
        examination_method:this.examination_method,
        jy_id:this.jy_id,
        course_coefficient:this.course_coefficient,
        difficulty_level:this.difficulty_level,
        course_attributes:this.course_attributes,
        theoretical_class_hours:this.theoretical_class_hours,
        practice_class_hours:this.practice_class_hours,
        status:this.status,
        introduction:this.introduction,
        num:this.num,
        total_num:this.total_num,
        week_num:this.week_num,
        js_id:this.js_id,
        xi_id: this.xi_id,
      }).then(res => {
        if (res.data.code == 1) {
          this.$message.success('添加成功');
          this.init()
          this.addSubject = false;
          this.getInfo();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    edit() {
      if (this.code == '' || !this.code) {
        return this.$message.error('请输入课程代码');
      }
      if (this.name == '' || !this.name) {
        return this.$message.error('请输入课程名称');
      }
      this.$api.setting
          .editSubject({
            code: this.code,
            name: this.name,
            school_discipline_type_id:this.school_discipline_type_id,
            course_nature:this.course_nature,
            examination_method:this.examination_method,
            jy_id:this.jy_id,
            course_coefficient:this.course_coefficient,
            difficulty_level:this.difficulty_level,
            course_attributes:this.course_attributes,
            theoretical_class_hours:this.theoretical_class_hours,
            practice_class_hours:this.practice_class_hours,
            status:this.status,
            introduction:this.introduction,
            id: this.editid,
            num:this.num,
            total_num:this.total_num,
            week_num:this.week_num,
            js_id:this.js_id,
            xi_id: this.xi_id,
          })
          .then(res => {
            if (res.data.code == 1) {
              this.$message.success('修改成功');
              this.init()
              this.addSubject = false;
              this.getInfo();
            } else {
              this.$message.error(res.data.msg);
            }
          });
    },
    // 删除学科
    delSubject(id) {
      this.$confirm('确定要删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$api.setting
            .delSubject({
              id: id
            })
            .then(res => {
              if (res.data.code == 1) {
                this.$message.success('删除成功');
                this.getInfo();
              } else {
                this.$message.error(res.data.msg);
              }
            });
      });
    }
  }
};
</script>

<style scoped lang="scss">
#subject {
  .nav-tab-bar-box {
    background-color: #ffffff;
    padding: 0.52vw;
    margin-top: 1.04vw;
    .nav-tab-bar {
      .nav-tab-item {
        color: #6e7a87;
        padding: 0 0.78125vw;
        border-right: 1px solid #f3f3f3;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      &div:last-child {
        border-right: none;
      }
      .active {
        font-size: 0.833vw;
        color: #63acff;
        position: relative;
        font-weight: bold;
      }
      .active::after {
        position: absolute;
        content: '';
        height: 2px;
        left: 0.78125vw;
        right: 0.78125vw;
        background-color: #63acff;
        bottom: -0.6vw;
      }
    }
  }
  .content {
    margin-top: 10px;
    background-color: #ffffff;
    height: 80vh;
    padding: 0 25px;
    .title {
      padding: 15px 0 15px 0;
    }
    .el-table {
      table-layout: fixed;
    }
    .el-table th > .cell,
    .el-table .cell {
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*不换行*/
      text-overflow: ellipsis; /*超出部分文字以...显示*/
    }
    .el-table thead {
      background: #fafafa !important;
    }
    .el-table th {
      padding: 15px 5px !important;
      background: #fafafa !important;
    }
    .el-table::before {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0;
    }
    .el-table .btn {
      padding: 5px 10px;
      text-align: center;
      font-size: 12px;
    }
  }
  .el-dialog__header {
    border-bottom: 1px solid #eeeeee;
    padding: 10px 20px;
    .el-dialog__title {
      font-size: 16px;
    }
  }
  .el-dialog__body {
    padding: 0 20px 35px 20px;
    .edit-box {
      overflow: auto;
      height: 600px;
      .input-box {
        margin-top: 35px;
        padding-left: 15px;
        .input-lebal {
          color: #aeb5b8;
          margin-right: 10px;
          span {
            color: #ff7a65;
            margin-right: 3px;
          }
        }
        .el-icon-remove-outline {
          font-size: 1.5625vw;
          color: #d7d9db;
          margin-left: 10px;
        }
      }
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
      }
      .avatar {
        width: 90px;
        height: 90px;
        display: block;
      }
    }
    .update_box {
      padding: 40px 20px;
      .update_item {
        background-color: #f6f8fb;
        padding: 20px;
        a {
          color: #007aff;
          text-decoration: none;
        }
      }
      .upload-file{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }
      .file_box{
        padding: 5px;
        .el-icon-circle-check{
          display: block;
        }
        .el-icon-circle-close{
          display: none;
        }
      }
      .file_box:hover{
        color: #007AFF;
        background-color: #F6F8FB;
        .el-icon-circle-check{
          display: none;
        }
        .el-icon-circle-close{
          display: block;
        }
      }
    }
  }
  .el-dialog__footer {
    padding: 15px 20px 15px;
    box-shadow: 0px -5px 10px -5px #eeeeee;
  }
}
</style>
